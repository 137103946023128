/**
Demonstrate CSS Variables
- consider moving from SASS completely
 */

:root {
  /*************
   PALETTE
   *************/
  --theme-white: #fff;
  --theme-black: #0c120c;

  --theme-gray-dark: #646a6f;
  --theme-gray: #7d8c9c;
  --theme-gray-lite: #b2b0ba;

  --theme-darkblue: #232c33;
  --theme-medblue: #394a5d; /* SASS darken 7% */
  --theme-blue-hover: #41546a; /* SASS darken 3% */
  --theme-blue: #475c73;

  --theme-green: #3f833f; /* #82c187; */
  --theme-red: #f46036;
  --theme-yellow: #7b781e;

  --text-color: var(--theme-black);
  --text-color-white: var(--theme-white);
  --link-color: var(--theme-green);

  /*************
  TYPOGRAPHY
  *************/
  --font-base: 'Titillium Web', sans-serif;
  --font-header: 'Rock Salt', --font-base;

  --font-size-h1: 4rem;
  --font-size-h2: 2.3rem;
  --font-size-h3: 1.8rem;
  --font-size-h4: 1.5rem;
  --font-size-h5: 1.3rem;
  --font-size-h6: 1.1rem;
}

h1,
h2,
h3 {
  font-family: var(--font-header);
}

h4 {
  font-family: var(--font-base);
}

h1,
h2 {
  font-weight: 700;
}

h1 {
  font-size: var(--font-size-h1);
  line-height: 8rem;
  color: var(--text-color-white);
}

h2 {
  font-size: var(--font-size-h2);
  line-height: var(--font-size-h1);
  color: var(--theme-yellow);
  letter-spacing: 0.2rem;
  text-align: center;
  margin: 0.5rem 0 1.2rem;
}

h3 {
  font-size: var(--font-size-h3);
  line-height: var(--font-size-h2);
  color: var(--text-color);
  margin: 0 0 1rem;
}

h4 {
  font-size: var(--font-size-h4);
  line-height: var(--font-size-h3);
  color: var(--text-color);
  font-weight: 700;
}

h5,
.h5 {
  font-size: var(--font-size-h5);
  line-height: var(--font-size-h2);
  color: var(--theme-yellow);
  font-weight: 700;
  letter-spacing: 0.2rem;
  font-family: var(--font-header);
}

p {
  line-height: 2.4rem;
  letter-spacing: 0.02rem;
  margin: 0 0 2rem;
}

a {
  color: var(--link-color);
}

a:hover,
a:focus {
  text-decoration: none;
}

a:active,
a:focus {
  outline: none;
}
