@use '../../../theme/app';

.ContactPage {
  margin-bottom: 8rem;

  .sectionHead {
    margin-bottom: 4rem;
  }

  article {
    margin-bottom: 4rem;

    h4 {
      text-align: left;
    }

    ul {
      display: inline-block;
    }
  }
}
