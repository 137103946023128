.skeletonWrap {
  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    max-width: 100%;
    min-width: 100%;
    padding: 0 4rem;
    margin-bottom: 6rem;
  }

  &.portfolio {
    li {
      max-width: 50%;
      min-width: 50%;
    }
  }

  &.resume {
    ul {
      justify-content: center;

      li {
        max-width: 80%;
        min-width: 80%;
      }
    }
  }

  @media (max-width: 674px) {
    ul {
      justify-content: center;
    }

    &.portfolio {
      ul {
        li {
          max-width: 43.5rem;
          width: 43.5rem;
          padding: 0.5rem 0;
        }
      }
    }

    &.resume {
      ul {
        li {
          max-width: 100%;
          min-width: 100%;
          padding: 0;
        }
      }
    }
  }
}
