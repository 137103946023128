li.ContactItem {
  display: flex;
  align-self: flex-start;
  margin-top: 1rem;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  div:first-child {
    margin-right: 1rem;
  }

  img {
    aspect-ratio: auto 80 / 80;

    @media screen and (max-width: 460px) {
      max-height: 5rem;
      max-width: 5rem;
    }

    @media screen and (max-width: 400px) {
      max-height: 4rem;
      max-width: 4rem;
    }
  }
}
