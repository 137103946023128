@use '../../../theme/typography' as type;

.errorBoundary {
  div {
    margin-top: 2rem;
  }

  h2 {
    font-family: type.$font-header;
    font-size: 2rem;
    line-height: 4rem;
    letter-spacing: 0.2rem;
    margin: 0 0 1rem;
  }

  pre {
    color: var(--theme-red);
  }
}
