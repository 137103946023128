@use '../../../theme/app';

.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.4rem;

  color: var(--text-color-white);
  background-color: var(--theme-blue);
  padding: 1rem;

  p {
    font-size: 1.1rem;
    line-height: 1.3rem;
    margin: 0;
  }
}
