@use '../../../theme/app';
@use '../../../theme/typography' as type;

.ResumePage {
  text-align: center;

  .sectionHead {
    margin: 0 0 2rem;

    a {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    span {
      display: flex;
      align-items: center;
      color: var(--theme-green);
      font-size: 1.3rem;
      font-family: type.$font-header;
      letter-spacing: 0.2rem;
    }

    img {
      aspect-ratio: auto 80 / 80;
    }
  }

  article {
    width: 75vw;
    max-width: 89.5rem;
    margin: 0 auto 8rem;

    h2 {
      font-family: type.$font-header;
      font-size: 2rem;
      line-height: 4rem;
      letter-spacing: 0.2rem;
      color: var(--text-color);
      margin: 0 0 1rem;
    }

    h3 {
      font-family: type.$font-base;
      font-weight: 700;
      color: var(--theme-yellow);
      margin-left: 0;
      margin-right: 0;
    }

    h4 {
      font-weight: 700;
      margin: 0 0 1rem;
    }

    ul {
      li {
        line-height: 2.8rem;
      }
    }
  }
}
