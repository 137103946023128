.navItem {
  display: inline-flex;
  align-items: center;
  font-size: 1.6rem;
  text-decoration: none;
  text-transform: capitalize;
  padding: 0.5rem 1.5rem;
  color: var(--text-color-white);
  opacity: 0.6;

  &.active {
    font-weight: 700;
    opacity: 1;
  }

  &:hover {
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
  }

  .icon {
    display: flex;
    margin-right: 0.6rem;
  }

  @media screen and (max-width: 530px) {
    .label {
      display: none;
    }
    .icon {
      margin: 0;
    }
  }
}
