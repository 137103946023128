nav {
}

.navbarMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--theme-darkblue);

  /* tablet */
  @media screen and (max-width: 768px) {
    justify-content: flex-end;
  }
}

// .internationalizer,
.router {
  ul {
    display: flex;
  }
}

/*
.internationalizer {
  padding: 0;

  button {
    padding: 0;
    margin: 0;
  }
}
*/

.NavBtn {
  padding: 0 0.2rem;
  cursor: pointer;

  a.active {
    color: var(--text-color-white);
  }
}
