/*
$web-font-path: 'https://fonts.googleapis.com/css?family=Rock+Salt|Titillium+Web:400,700&display=swap' !default;
@import url($web-font-path);
*/

$font-base: var(--font-base) !default;
$font-header: var(--font-header) !default;

$font-size-h1: var(--font-size-h1) !default;
$font-size-h2: var(--font-size-h2) !default;
$font-size-h3: var(--font-size-h3) !default;
$font-size-h4: var(--font-size-h4) !default;
$font-size-h5: var(--font-size-h5) !default;
$font-size-h6: var(--font-size-h6) !default;

$base-text-size: var(--font-size-h4);

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
