.mascot {
  position: absolute;
  z-index: 10;
  left: 0;
  padding: 0;

  img {
    cursor: pointer;
    transition: all 100ms ease-in-out;

    /* max screen */
    height: 18rem;
    margin-top: -0.5rem;
    padding: 0 3rem;
  }
}

@media screen and (max-width: 1300px) {
  .mascot {
    img {
      height: 16rem;
      margin: 0.4rem auto;
    }
  }
}

@media screen and (max-width: 1190px) {
  .mascot {
    img {
      height: 14rem;
    }
  }
}

@media screen and (max-width: 1024px) {
  .mascot {
    img {
      height: 13rem;
      margin: 0.8rem auto;
    }
  }
}

@media screen and (max-width: 825px) {
  .mascot {
    img {
      height: 12rem;
      margin: 0.8rem auto;
      padding: 0 1.5rem;
    }
  }
}

@media screen and (max-width: 650px) {
  .mascot {
    img {
      height: 11rem;
      margin: 1rem auto;
      padding: 0 3.5rem;
    }
  }
}

@media screen and (max-width: 560px) {
  .mascot {
    img {
      height: 10rem;
      padding: 0 2rem;
    }
  }
}

@media screen and (max-width: 460px) {
  .mascot {
    img {
      height: 8rem;
      margin: 1rem auto;
      padding: 0 1.5rem;
    }
  }
}

@media screen and (max-width: 400px) {
  .mascot {
    position: fixed;

    img {
      height: 5rem;
      margin: 0 auto;
    }
  }
}
