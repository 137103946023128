@use '../../../theme/app';

.Header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--theme-medblue);

  .headerWrap {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: app.$view-max-width;
  }

  &.shrink {
    background-color: transparent;
    padding-bottom: 0.75rem;

    .headerWrap {
      margin-top: -8rem;

      /* tablet */
      @media screen and (max-width: 768px) {
        margin-top: -7rem;
      }

      @media screen and (max-width: 460px) {
        margin-top: -5.2rem;
      }
    }

    /* minimized header row */
    nav > div {
      background-color: var(--theme-blue);
    }

    /* mascot */
    & > div > div {
      top: 8.2rem;

      /* tablet */
      @media screen and (max-width: 768px) {
        top: 7.2rem;
      }

      @media screen and (max-width: 460px) {
        top: 5.4rem;
      }

      @media screen and (max-width: 400px) {
        top: 0.2rem;
      }

      img {
        height: 5rem;
        margin: 0;
      }
    }
  }

  /* tablet */
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 3rem;
      line-height: 7rem;
    }
  }

  @media screen and (max-width: 460px) {
    h1 {
      font-size: 2.6rem;
      line-height: 5.2rem;
    }
  }

  /* small */
  @media screen and (max-width: 400px) {
    .headerWrap {
      margin-top: -5.2rem;
    }

    /* mascot */
    & > div > div {
      top: 0;
    }

    /* minimized header row */
    nav > div {
      background-color: var(--theme-blue);
    }
  }
}
