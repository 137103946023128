@use '../theme/typography' as type;
@use '../theme/reset' as *;
@use '../theme/app';

html {
  font-family: sans-serif;
  font-size: 62.5%; // rem:px 1:1
}

body {
  margin: 0;
  font-family: type.$font-base;
  font-size: type.$base-text-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ContainerFluid {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 auto;
  padding: 0;

  background-color: var(--theme-white);
  color: var(--text-color);
  text-align: center;
}

.ContentWrapper {
  position: relative;
  flex: 1 0 auto;
  width: 100%;
  max-width: app.$view-max-width;
  margin: 0 auto;
  padding: 3rem 2rem 6rem;
}
