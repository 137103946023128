.pageNotFound {
  max-width: 70rem;
  margin: 0 auto;

  .head {
    margin-bottom: 4rem;

    h2 {
      margin-bottom: 2rem;
    }
  }

  a {
    font-weight: 700;
    font-size: 1.8rem;
  }
}
